var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      attrs: {
        color: "indigo",
        dark: "",
        app: "",
        "clipped-left": "",
        "clipped-right": "",
        dense: ""
      }
    },
    [
      _c("v-app-bar-nav-icon", {
        on: {
          click: function($event) {
            return _vm.$emit("drawer-icon-click")
          }
        }
      }),
      _c("v-toolbar-title", [_vm._v("Tutti Management Console")]),
      _c("v-spacer"),
      _c("v-autocomplete", {
        attrs: {
          "hide-details": "",
          "cache-items": "",
          "solo-inverted": "",
          "hide-no-data": "",
          dense: "",
          rounded: "",
          items: _vm.prjNames,
          label: "Select existing project"
        },
        model: {
          value: _vm.prjName,
          callback: function($$v) {
            _vm.prjName = $$v
          },
          expression: "prjName"
        }
      }),
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: { fab: "", dark: "", small: "", icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.$refs.dialogCreateProject.show()
                            }
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-plus")])],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v("Create new project...")])]
      ),
      _c("v-spacer"),
      _c("server-status-menu-button", { attrs: { duct: _vm.duct } }),
      _c(
        "v-btn",
        {
          attrs: { icon: "", plain: !_vm.eventDrawer },
          on: {
            click: function($event) {
              return _vm.$emit("event-nav-icon-click")
            }
          }
        },
        [
          _c(
            "v-icon",
            { attrs: { color: _vm.eventDrawer ? "yellow darken-2" : "" } },
            [_vm._v("mdi-lightning-bolt")]
          )
        ],
        1
      ),
      _c("tutti-dialog", {
        ref: "dialogCreateProject",
        attrs: {
          title: "Create New Project",
          maxWidth: "400",
          allowEnter: true,
          actions: [
            {
              label: "Create",
              color: "indigo darken-1",
              disableByRule: true,
              text: true,
              onclick: _vm.createProject
            },
            {
              label: "Cancel",
              color: "grey darken-1",
              text: true
            }
          ]
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c("v-text-field", {
                  attrs: {
                    autofocus: "",
                    filled: "",
                    "prepend-icon": "mdi-pencil",
                    label: "Enter Project Name",
                    rules: [_vm.rules.required, _vm.rules.alphanumeric]
                  },
                  model: {
                    value: _vm.newPrjName,
                    callback: function($$v) {
                      _vm.newPrjName = $$v
                    },
                    expression: "newPrjName"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }