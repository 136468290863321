var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("app-bar", {
        attrs: { duct: _vm.duct, eventDrawer: _vm.eventDrawer },
        on: {
          "drawer-icon-click": _vm.toggleMenuDrawer,
          "event-nav-icon-click": _vm.toggleEventDrawer,
          "project-name-update": _vm.updateProjectName
        }
      }),
      _c("menu-drawer", { attrs: { drawer: _vm.menuDrawer } }),
      _c("event-drawer", {
        attrs: { duct: _vm.duct, drawer: _vm.eventDrawer }
      }),
      _c(
        "keep-alive",
        [
          _vm.duct
            ? _c("router-view", {
                ref: "child",
                attrs: { app: "", duct: _vm.duct, prjName: _vm.prjName }
              })
            : _vm._e()
        ],
        1
      ),
      _c("tutti-snackbar", {
        ref: "snackbarSuccess",
        attrs: { color: "success", timeout: 5000 }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }