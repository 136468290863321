var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: { app: "", clipped: "", right: "", width: "300px" },
      model: {
        value: _vm.drawer,
        callback: function($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer"
      }
    },
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.logs,
          "items-per-page": 10,
          "hide-default-footer": ""
        },
        scopedSlots: _vm._u([
          {
            key: "item.msg",
            fn: function(ref) {
              var item = ref.item
              return [
                !item.received
                  ? _c("v-icon", { attrs: { small: "", color: "warning" } }, [
                      _vm._v(" mdi-clock-outline ")
                    ])
                  : item.received.Status == "Success"
                  ? _c("v-icon", { attrs: { small: "", color: "success" } }, [
                      _vm._v(" mdi-check-circle ")
                    ])
                  : item.received.Status == "Error"
                  ? _c("v-icon", { attrs: { small: "", color: "error" } }, [
                      _vm._v(" mdi-alert ")
                    ])
                  : _vm._e(),
                _c("b", [
                  _vm._v(
                    " " + _vm._s(item.evtName) + " (" + _vm._s(item.eid) + ")"
                  )
                ]),
                item.received
                  ? _c(
                      "div",
                      { staticStyle: { width: "100%", "text-align": "right" } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.dateFormat(
                                item.received.Timestamp.Requested * 1000,
                                "yyyy-mm-dd HH:MM:ss"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  : _vm._e(),
                _c("vue-json-pretty", { attrs: { data: item.sent, deep: 1 } }),
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c(
                    "div",
                    { staticStyle: { "margin-right": "5px" } },
                    [
                      _c("v-icon", { attrs: { "x-small": "" } }, [
                        _vm._v("mdi-arrow-right")
                      ])
                    ],
                    1
                  ),
                  item.received
                    ? _c(
                        "div",
                        [
                          item.received.Status == "Success"
                            ? _c("vue-json-pretty", {
                                attrs: { data: item.received.Contents, deep: 1 }
                              })
                            : _c("vue-json-pretty", {
                                attrs: { data: item.received.Reason, deep: 1 }
                              })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ]
            }
          }
        ])
      }),
      _c("v-divider")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }