var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.profile
    ? _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function(ref) {
                  var on = ref.on
                  var attrs = ref.attrs
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        _vm._b(
                          {
                            staticClass: "text-none",
                            attrs: {
                              depressed: "",
                              color: _vm.profile[_vm.status].btn.color
                            }
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [
                        _vm._v(
                          " " + _vm._s(_vm.profile[_vm.status].btn.label) + " "
                        ),
                        _vm.status == "connected"
                          ? _c("span", { staticClass: "text-caption ml-2" }, [
                              _vm._v(
                                " (last pinged: " +
                                  _vm._s(_vm.lastPinged) +
                                  ") "
                              )
                            ])
                          : _vm.status == "disconnected"
                          ? _c("span", { staticClass: "text-caption ml-2" }, [
                              _vm._v(
                                " (Auto-retry remaining: " +
                                  _vm._s(_vm.retry.maxCnt - _vm.retry.cnt) +
                                  ") "
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ]
                }
              }
            ],
            null,
            false,
            3394562399
          )
        },
        [
          _c(
            "v-list",
            _vm._l(_vm.profile[_vm.status].btn.menu, function(menu, index) {
              return _c(
                "v-list-item",
                {
                  key: index,
                  on: {
                    click: function($event) {
                      return menu.handler()
                    }
                  }
                },
                [_c("v-list-item-title", [_vm._v(_vm._s(menu.title))])],
                1
              )
            }),
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }