var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      attrs: { app: "", clipped: "", left: "" },
      model: {
        value: _vm.drawer,
        callback: function($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer"
      }
    },
    [
      _c(
        "v-list",
        { attrs: { nav: "", dense: "" } },
        [
          _c(
            "v-list-item-group",
            { attrs: { "active-class": "indigo--text text--accent-4" } },
            [
              _c(
                "v-list-item",
                { attrs: { to: "/console/dashboard/" } },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-view-dashboard")])],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v("Dashboard")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-list-item-group",
            { attrs: { "active-class": "indigo--text text--accent-4" } },
            [
              _c("v-subheader", [_vm._v("DESIGN & TEST")]),
              _c(
                "v-list-item",
                { attrs: { to: "/console/template/" } },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-iframe-outline")])],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v("Templates")])
                ],
                1
              ),
              _c(
                "v-list-item",
                { attrs: { to: "/console/flow/" } },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-wrench")])],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v("Scheme")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-list-item-group",
            { attrs: { "active-class": "indigo--text text--accent-4" } },
            [
              _c("v-subheader", [_vm._v("PUBLISH & COLLECT")]),
              _c(
                "v-list-item",
                { attrs: { to: "/console/response/" } },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-comment-check-outline")])],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v("Responses")])
                ],
                1
              ),
              _c(
                "v-list-group",
                {
                  attrs: { "prepend-icon": "mdi-account-group", value: false },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function() {
                        return [
                          _c("v-list-item-title", [_vm._v("Worker Platforms")])
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "v-list-item",
                    {
                      staticClass: "pl-6",
                      attrs: { to: "/console/platform/mturk/" }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-amazon")])],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Amazon MTurk")])
                    ],
                    1
                  ),
                  _c(
                    "v-list-item",
                    {
                      staticClass: "pl-6",
                      attrs: { to: "/console/platform/private/" }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _c("v-icon", [
                            _vm._v("mdi-account-supervisor-circle")
                          ])
                        ],
                        1
                      ),
                      _c("v-list-item-title", [_vm._v("Partner-Sourcing")])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-list-item-group",
            { attrs: { "active-class": "indigo--text text--accent-4" } },
            [
              _c("v-subheader", [_vm._v("OTHERS")]),
              _c(
                "v-list-item",
                { attrs: { to: "/console/event/" } },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-lightning-bolt")])],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v("Duct Events")])
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  attrs: {
                    href: "https://iflb.github.io/tutti/",
                    target: "_blank"
                  }
                },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-file-document-outline")])],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v("Documentation")]),
                  _c(
                    "v-list-item-action",
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v("mdi-launch")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }